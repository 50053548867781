import * as React from "react"

export default function Forward({ className, width }) {
    return (
        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            width={width ? width : 24}
            viewBox="0 0 256 256"
        >
            <path fill="none" d="M0 0h256v256H0z" />
            <path fill="currentColor" d="M208 40v176a8 8 0 01-16 0v-73.736L72.344 215.388A16 16 0 0148 201.735V54.265A16 16 0 0172.343 40.61L192 113.735V40a8 8 0 0116 0z" />
        </svg>
    )
}
