import * as React from "react"

export default function Play({ className, width }) {
    return (
        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            width={width ? width : 24}
            viewBox="0 0 256 256"
        >
            <path fill="none" d="M0 0h256v256H0z" />
            <path
                fill="currentColor"
                d="M128 24a104 104 0 10104 104A104.12 104.12 0 00128 24zm36.438 110.656l-48 32A7.996 7.996 0 01104 160V96a7.996 7.996 0 0112.438-6.656l48 32a8 8 0 010 13.312z"
            />
        </svg>
    )
}
