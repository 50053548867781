import * as React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/swiper-bundle.min.css"

export default function SwipeStream({ channels }) {
    return (
        <div className="z-10 fixed left-0 bottom-0 right-0 p-2 h-14 bg-black">
            <Swiper
                freeMode={true}
                spaceBetween={10}
                scrollbar={{ draggable: true }}
            >
                {channels.nodes.map(channel => {
                    const image = getImage(channel.data.image.localFile)

                    return (
                        <SwiperSlide key={channel.uid}>
                            <Link
                                className="flex opacity-50 hover:opacity-100"
                                to={`/player-${channel.uid}`}
                                title={`Ver ${channel.data.name.text} en directo`}
                            >
                                <GatsbyImage
                                    image={image}
                                    alt={channel.data.name.text}
                                />
                            </Link>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </div>
    )
}
