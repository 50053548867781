import React, { useState, useRef, useEffect } from "react"
import { InView } from "react-intersection-observer"

export default function AdSlot({ html }) {
    const [loaded, setLoaded] = useState(false)
    const myRef = useRef(null)

    const handleChange = inView => {
        if (inView && !loaded) {
            const slotHtml = document
                .createRange()
                .createContextualFragment(html)
            myRef.current.innerHTML = ""
            myRef.current.appendChild(slotHtml)
            setLoaded(true)
        }
    }

    useEffect(() => {
        if (!html) return

        return () => {
            if (window.googletag) {
                window.googletag.destroySlots()
            }
        }
    }, [html])

    if (!html) return ``

    return (
        <InView triggerOnce={true} onChange={inView => handleChange(inView)}>
            <div className="adslot-wrapper py-4" ref={myRef}></div>
        </InView>
    )
}
