import * as React from "react"

export default function Volume({ className, width }) {
    return (
        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            width={width ? width : 24}
            viewBox="0 0 256 256"
        >
            <path fill="none" d="M0 0h256v256H0z" />
            <path
                fill="currentColor"
                d="M155.51 24.81a7.993 7.993 0 00-8.422.875L77.244 79.997h-45.25a16.016 16.016 0 00-16 16v64a16.016 16.016 0 0016 16h45.25l69.844 54.313a7.995 7.995 0 0012.906-6.313v-192a7.994 7.994 0 00-4.484-7.187zM191.994 96a8 8 0 00-8 8v48a8 8 0 0016 0v-48a8 8 0 00-8-8zM223.994 80a8 8 0 00-8 8v80a8 8 0 0016 0V88a8 8 0 00-8-8z"
            />
        </svg>
    )
}
