import * as React from "react"

export default function PlayAlt({ className, width }) {
    return (
        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            width={width ? width : 24}
            viewBox="0 0 256 256"
        >
            <path fill="none" d="M0 0h256v256H0z" />
            <path
                fill="currentColor"
                d="M232.313 114.344L88.343 26.359A15.998 15.998 0 0064 40.008v175.984a16.005 16.005 0 0024.344 13.649l143.969-87.985a16.007 16.007 0 000-27.312z"
            />
        </svg>
    )
}
